<template>
  <div>
    <v-card class="primary-shade--light pa-3">
      <h1 class="text-center subtitle-4 black--text">الرصيد المخزني</h1>
      <v-row class="mt-5">
        <v-spacer></v-spacer>
        <v-col md="4" cols="12">
          <div class="d-flex flex-row">
            <v-text-field v-model="table.search" label="البحث" outlined dense @keyup.enter="search()"></v-text-field>
            <button class="search-btn" @click="search()">
              <v-icon style="font-size: 18px; color: white"> fa-search </v-icon>
            </button>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-data-table :headers="table.headers" loading-text="جاري التحميل ... الرجاء الانتظار" :items="table.Data"
            :options.sync="tableOptions" :server-items-length="table.totalData" :loading="table.loading"
            class="elevation-1" :footer-props="{
              itemsPerPageOptions: [10, 50, 100],
            }">
            <template slot="item._id" slot-scope="props">
              {{ table.totalData - props.index - (tableOptions.page - 1) * tableOptions.itemsPerPage }}
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card>
    <!--- Dailog for show info to user-->
    <v-dialog v-model="dialogData.open" max-width="500px">
      <v-toolbar :color="dialogData.color" dense></v-toolbar>
      <v-card>
        <v-card-title class="headline justify-center"> {{ dialogData.bodyText }} </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1 justify-start" text @click="dialogData.open = false"> تم </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Api from '@/api/api';
import { mdiCash, mdiCashMultiple } from '@mdi/js';

export default {
  data() {
    return {
      icons: {
        mdiCashMultiple,
        mdiCash,
      },

      dialogData: {
        open: false,
        color: 'primary',
        bodyText: 'test',
      },

      xlsxData: {
        list: null,
        listLoading: true,
        downloadLoading: false,
        filename: 'الرصيد المخزني',
        autoWidth: true,
        bookType: 'xlsx',
      },

      allData: [],

      isScreenXs: false,


      tableOptions: {},

      table: {
        search: null,
        totalData: 0,
        Data: [],
        loading: true,

        headers: [
          {
            text: '#',
            align: 'start',
            sortable: false,
            value: '_id',
            width: 1,
          },
          { text: 'المجهز', sortable: false, value: 'suppliers_name' },
          { text: 'المادة', sortable: false, value: 'material_name' },
          { text: 'الكمية الموجودة', sortable: false, value: 'count' },
        ],
      },
    }
  },
  watch: {
    '$route.query.search': {
      handler(search) {
        this.table.search = search
        this.getDataAxios(search)
      },
      deep: true,

      // immediate: true,
    },
  },

  created() {
    this.getDataAxios()
  },
  methods: {
    async getDataAxios() {
      let { search } = this.table
      this.table.loading = true
      let { page, itemsPerPage } = this.tableOptions
      if (!search) {
        search = ''
      }

      if (!page) {
        page = 1
      }
      if (!itemsPerPage) {
        itemsPerPage = 10
      }

      const response = await Api.getStoreSellingGroup({
        search,
        page,
        limit: itemsPerPage,
      })

      if (response.status === 401) {
        this.$store.dispatch('submitLogout')
      } else if (response.status === 500) {
        this.table.loading = false
        this.showDialogfunction(response.data.results, '#FF5252')
      } else {
        this.table.loading = false
        this.table.Data = response.data.results.data
        this.table.totalData = response.data.results.count
      }
    },

    search() {
      this.$router.replace(
        {
          query: { search: this.table.search },
        },
        () => { },
      )
    },

    showDialogfunction(bodyText, color) {
      this.dialogData.open = true
      this.dialogData.bodyText = bodyText
      this.dialogData.color = color
    },

    async getAllDataAxios() {
      this.xlsxData.downloadLoading = true

      const response = await Api.getAllStoreSelling()

      if (response.status === 401) {
        this.$store.dispatch('submitLogout')
      } else if (response.status === 500) {
        this.xlsxData.downloadLoading = false
        this.showDialogfunction(response.data.results, '#FF5252')
      } else {
        this.allData = response.data.results
        this.handleDownload()
      }
    },

    handleDownload() {
      import('@/vendor/Export2Excel').then(excel => {
        const tHeader = [
          'المجهز',
          'المادة',
          'المبلغ الكلي',
          'المبلغ المدفوع',
          'المبلغ المتبقي',
          'الملاحظات',
        ]
        const filterVal = [
          'suppliers_name',
          'material_name',
          'allPrice',
          'store_selling_paid_price',
          'remainingPrice',
          'store_selling_details',
        ]

        // const { list } = this
        const data = this.formatJson(filterVal, this.allData)
        excel.export_json_to_excel({
          header: tHeader,
          data,
          filename: this.xlsxData.filename,
          autoWidth: this.xlsxData.autoWidth,
          bookType: this.xlsxData.bookType,
        })
        this.xlsxData.downloadLoading = false
      })
    },

    formatJson(filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => v[j]))
    },

    isNumber(evt) {
      const keysAllowed = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.']
      const keyPressed = evt.key
      if (!keysAllowed.includes(keyPressed)) {
        evt.preventDefault()
      }
    },
  },
}
</script>

<style scoped>
a {
  text-decoration: none;
}

.search-btn {
  width: 49px;
  height: 40px;
  padding: 10px;
  background: #2196f3;
  font-size: 17px;
  border: 1px solid grey;
  border-right: none;
  cursor: pointer;
  line-height: 100%;
  border-radius: 7px;
}

table.v-table tbody td {
  color: black;
}
</style>
